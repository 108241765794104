import Link from "next/link";

type Props = {
  author: string;
  date: string;
  updatedOn: string;
  readTime: string;
  authorLink: string;
};

// Blog Info (Author, Post Date, Last Updated, Read Time )
const CarouselBlogInfo: React.FC<Props> = ({
  author,
  authorLink,
  date,
  updatedOn,
  readTime,
}) => {
  return (
    <div className="xl:py-3 text-xs xs:py-2 leading-4 flex flex-wrap">
      <div className="mr-7 mb-1">
        by&nbsp;
        <span className="text-blue-600">
          <Link href={authorLink}>{author}</Link>
        </span>
      </div>
      {/* <div className="mr-7 mb-1">{date}</div> */}
      <div className="mr-7 mb-1">Published on {updatedOn}</div>
      <div className="">{readTime} read</div>
    </div>
  );
};

export default CarouselBlogInfo;
