import Head from "next/head";
import { IMeta } from "../Interfaces/meta";
import { useRouter } from "next/router";
import { useEffect, useState } from "react";
import { integer } from "aws-sdk/clients/cloudfront";

export interface MetaContent {
  meta: IMeta;
  shareImage: string;
  ogType?: string;
  customNoFollow?: integer;
}

const MetaBody = ({ props }: { props: MetaContent }) => {
  const { asPath, pathname } = useRouter();

  const { meta, shareImage, ogType, customNoFollow } = props;

  const metTitle = "Knowledgehut Blog";
  const metaDescription = "Resources For Your Learning";

  const ogTitle = meta?.og_title || meta?.meta_title || metTitle;
  const ogDescription =
    meta?.og_description || meta?.meta_description || metaDescription;

  const twitterTitle = meta?.twitter_title || meta?.meta_title || metTitle;
  const twitterDescription =
    meta?.twitter_description || meta?.meta_description || metaDescription;

  const requestedPage = `${process.env.NEXT_PUBLIC_WEB_DOMAIN}${asPath}`;

  const [pageURL, setPageURL] = useState(requestedPage);

  useEffect(() => {
    setPageURL(window.location.href);
  }, []);

  return (
    <Head>
      <title>{meta?.meta_title || metTitle}</title>
      <meta
        name="description"
        content={meta?.meta_description || metaDescription}
      />

      <meta property="og:type" content={ogType || "website"} />
      <meta name="og:title" property="og:title" content={ogTitle} />
      <meta
        name="og:description"
        property="og:description"
        content={ogDescription}
      />
      <meta property="og:url" content={pageURL} />
      <meta
        property="og:image"
        content={`${process.env.NEXT_PUBLIC_AWS_URI}/${shareImage}`}
      />

      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:title" content={twitterTitle} />
      <meta name="twitter:description" content={twitterDescription} />
      <meta name="twitter:site" content="@knowledgehut" />
      <meta name="twitter:creator" content="@knowledgehut" />
      <meta property="twitter:url" content={pageURL} />
      <meta
        name="twitter:image"
        content={`${process.env.NEXT_PUBLIC_AWS_URI}/${shareImage}`}
      />

      { meta?.modified_time && <meta property="article:modified_time" content={meta?.modified_time}/> }
      { meta?.published_time && <meta property="article:published_time" content={meta?.published_time} /> }

      {(meta?.nofollow == 1 || customNoFollow == 1) && (
        <meta name="robots" content="noindex, nofollow" />
      )}

      <link rel="canonical" href={meta?.canonical || pageURL} />
    </Head>
  );
};
export default MetaBody;
