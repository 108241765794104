import React from "react";
import Link from "next/link";
import { TopicsProps } from "./interface";
import { trackGa4Events } from "../../lib/events/ga4/tracker";

const Topics: React.FC<TopicsProps> = ({ data }) => {
  const { topicName, topicUrl, icon, ga4EventKey } = data;
  return (
    <Link href={topicUrl} passHref>
      <a 
       onClick={() => {                
        ga4EventKey ? trackGa4Events(ga4EventKey) : '';
      }}
      className=" h-56 mb-5 border border-gray-300 rounded  flex flex-col justify-center items-center hover:border-transparent hover:shadow-2 bg-white">
        <i className={`${icon} blog-icons inline-block text-center`}></i>
        <p className="mt-5 font-bold text-sm">{topicName}</p>
      </a>
    </Link>
  );
};

export default Topics;
