import { LeftArrow, RightArrow } from "./CarouselArrows";
type Config = {
  arrows: boolean;
  infinite: boolean;
  speed: number;
  slidesToShow: number;
  prevArrow: JSX.Element;
  nextArrow: JSX.Element;
  //lazyLoad: any;
};
const CarouselConfig: Config = {
  // To show Arrows
  arrows: true,
  // Infinite Scroll
  infinite: true,
  // Transition speed (Default: 500ms)
  speed: 1000,
  // Slide to show each time (Default: 1)
  slidesToShow: 1,
  // Lazy load (ondemand | progressive)
  //lazyLoad: "ondemand",
  // Custom Arrows for Carousel
  prevArrow: <LeftArrow />,
  nextArrow: <RightArrow />,
};
export default CarouselConfig;
