import { MouseEventHandler } from "react";
type ArrowProps = {
  onClick?: MouseEventHandler<HTMLDivElement> | any;
};
// Left Arrow
export const LeftArrow: React.FC<ArrowProps> = (props) => {
  const { onClick } = props;
  return (
    <div
      className="absolute flex justify-center items-center xl:top-36 top-10p left-1 xl:left-2 z-10 text-gray-100 bg-green-1 rounded-full md:h-35 md:w-35 xs:h-5 xs:w-5 cursor-pointer"
      onClick={onClick}
    >
      <i className="blog-icons icon-left-arrow-white-large xl:inline-block hidden"></i>
      <i className="blog-icons icon-left-arrow-white-small xl:hidden inline-block"></i>
    </div>
  );
};
// Right Arrow
export const RightArrow: React.FC<ArrowProps> = (props) => {
  const { onClick } = props;
  return (
    <div
      className="absolute flex justify-center items-center xl:top-36 top-10p right-1 xl:right-2 z-10 text-gray-100 bg-green-1 rounded-full md:h-35 md:w-35 xs:h-5 xs:w-5 cursor-pointer"
      onClick={onClick}
    >
      <i className="blog-icons icon-right-arrow-white-large xl:inline-block hidden"></i>
      <i className="blog-icons icon-right-arrow-white-small xl:hidden inline-block"></i>
    </div>
  );
};