import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import TrendingItem from "./TrendingItem";
import { TrendingCarouselData } from "./Interface";
import TrendingCarouselProps from "./Interface";
import CarouselContainer from "../CarouselContainer";
import Button from "../Button";
import Link from "next/link";

const TrendingCarousel: React.FC<TrendingCarouselProps> = ({ data }) => {
  // Settings for Slider
  const settings: {} = {
    arrows: false,
    dots: true,
    slidesToShow: 1,
    // slidesToScroll: 3,
    // slidesPerRow: 3,
    autoplay: true,
    speed: 500,
    autoplaySpeed: 5000,
    pauseOnHover: true,
    // vertical: true,
    rows: 3,
    // dotsClass: "slick-dots slick-thumb",
    // customPaging: function(i) {
    //     return (
    //       <span className="px-1 py-0.5 m-1 rounded-full bg-black"></span>
    //     );
    // },
  };

  return (
    <div className="">
      {/* Heading */}
      <h2 className="mb-5 uppercase text-black font-bold text-sm relative before:absolute before:w-10 before:h-1 before:bg-black before:bottom-0 py-4 before:rounded-2xl">
        TRENDING POSTS
      </h2>

      {/* Slider container */}
      <div className="shadow-2 px-7 py-2 pb-10 rounded">
        {/* Carousel container with react-slick styles */}
        <CarouselContainer>
          {/* React-slick Slider component */}
          <Slider className="" {...settings}>
            {/* Slides */}
            {data.map((item: TrendingCarouselData, index: number) => (
              <TrendingItem {...item}  key={index} />
            ))}
          </Slider>
        </CarouselContainer>
      </div>
      <div className="pt-6">
        <Link href="/blog/write-for-us">
          <a>
            <div className="px-2 rounded py-1 bg-black">
              <Button
                text="Write For US"
                variant="outlinedWhite"
                buttonSize="screen"
                endIcon="icon-right-arrow-pink"
              />
            </div>
          </a>
        </Link>
      </div>
    </div>
  );
};

export default TrendingCarousel;
