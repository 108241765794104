import React, { useEffect, useState } from "react";
import Image from "next/image";
import Link from "next/link";
import { Props } from "./interface";
import styled from "styled-components";
import { trackGa4Events } from "../../lib/events/ga4/tracker";
const BlogCardContainer = styled.div`
  background-color: #fff;
  margin-bottom: 0px;
  border: 1px solid #ccc;
  border-radius: 4px;
`;
const BlogCard: React.FC<Props> = ({ data, priorityState, isShowPublishedDate }) => {
  const {
    imageUrl,
    defaultImageUrl,
    badge,
    viewers,
    heading,
    author,
    readingtime,
    date,
    description,
    viewPageUrl,
    authorPageUrl,
    badgeUrl,
    altName,
    id,
    published_date,
  } = data;
  const [src, setSrc] = useState<string>(imageUrl);

  useEffect(() => {
    setSrc(imageUrl);
  }, [data]);

  return (
    <BlogCardContainer className="hover:shadow-2">
      {/* Image */}
      <div className="w-full">
        <Link href={viewPageUrl} passHref >
          {/* # will be replaced by link */}
          <a className="w-full h-5" 
            onClick={()=>{             
              trackGa4Events( `click-latest-posts-latest-posts{${heading}}`);
            }}
          >
            {priorityState ? (
              <Image
                width="100%"
                height={45}
                layout="responsive"
                src={`${process.env.NEXT_PUBLIC_AWS_URI}/${src}`}
                alt={altName}
                className="object-fill"
                onError={() => setSrc(defaultImageUrl)}
                priority={priorityState}
              />
            ) : (
              <Image
                width="100%"
                height={45}
                layout="responsive"
                src={`${process.env.NEXT_PUBLIC_AWS_URI}/${src}`}
                alt={altName}
                className="object-fill"
                onError={() => setSrc(defaultImageUrl)}
              />
            )}
          </a>
        </Link>
      </div>
      <div className="md:h-56 xs:h-auto flex-col flex gap-1 py-3 xs:px-3 md:px-5">
        {/* Badge & view icon */}
        <div className="flex justify-between h-5">
          <div className="">
            {/* # will be replaced by link */}
            {badge && badgeUrl && (
              <Link href={badgeUrl} passHref>
                <a
                  onClick={()=>{     
                    trackGa4Events(`click-latest-posts-category-latest-posts{${badge}}`);
                    // trackGa4Events( `click-latest-posts-latest-posts{${viewPageUrl.substring(viewPageUrl.lastIndexOf('/')+1)}}`);
                  }}
                  className="text-black font-medium text-xs border border-black rounded-full px-3 inline-block leading-5"
                  type="button"
                >
                  {badge}
                </a>
              </Link>
            )}
          </div>
          <div className="flex items-center">
            <i className="blog-icons icon-eye"></i>
            <span className="ml-1 text-xs">{viewers}</span>
          </div>
        </div>
        {/* Title */}
        <div className="pt-2">
          <Link href={viewPageUrl} passHref>
            <a
            onClick={()=>{             
              // trackGa4Events( `click-latest-posts-latest-posts{${viewPageUrl.substring(viewPageUrl.lastIndexOf('/')+1)}}`); 
              trackGa4Events( `click-latest-posts-latest-posts{${heading}}`); 
            }}
            >
              <span className="flex line-clamp-2 md:text-base xs:text-xs font-bold text-gray-900 md:h-12 xs:h-8">
                {heading}
              </span>
            </a>
          </Link>
        </div>
        {/* Blog info */}
        <div className="pt-1 pb-2">
          <div
            className="line-clamp-2 inline md:text-sm xs:text-xs md:h-10 xs:h-8"
            dangerouslySetInnerHTML={{ __html: description.substring(0, 150) }}
          ></div>
          <Link href={viewPageUrl} passHref>
            <a
             onClick={()=>{             
              trackGa4Events( `click-latest-posts-latest-posts{${heading}}`);
            }} 
            className="inline text-xs font-bold text-blue-600">Read More</a>
          </Link>
        </div>
        {/* Author & Date */}
        <div className="flex justify-between flex-nowrap h-9 gap-1 text-xs border-t pt-3">
          <p className="h-4 align-middle leading-4">
            by{" "}
            <Link href={authorPageUrl} passHref>
              <a className="inline-block h-4"
                 onClick={()=>{             
                  trackGa4Events( `click-latest-posts-author{${author}}`);
                }}
              >
                <span className="text-blue-600 font-bold whitespace-nowrap overflow-ellipsis overflow-hidden max-w-140 inline-block align-top">
                  {author}
                </span>
              </a>
            </Link>
          </p>
          <p className="h-4">{isShowPublishedDate ? published_date : date}</p>
          {/* <p className="pr-3 pl-1">{readingtime}</p> */}
        </div>
      </div>
    </BlogCardContainer>
  );
};
export default BlogCard;
