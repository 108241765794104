import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import CarouselContainer from "../CarouselContainer";
import SearchBar from "../SearchBar";
import FeaturedPostItem from "./FeaturedPostItem";
import { FeaturePost, FeaturesPostsData } from "./Interface";
import CarouselConfig from "./CarouselConfig";
const FeaturedPosts: React.FC<FeaturesPostsData> = ({ data }) => {
  return (
    <div className="relative">
      {/* Heading & SearchBar */}
      <div className="flex flex-col justify-between xl:items-center md:flex-row text-left">
        <h1 className="mb-5 uppercase text-black font-bold text-sm relative before:absolute before:w-10 before:h-1 before:bg-black before:bottom-0 py-4 before:rounded-2xl">
          Featured Blog POSTS
        </h1>
        <div className="md:w-250 md:py-3 sm:py-0 xs:w-full xs:pt-0 xs:pb-3 mb-0 sm:mb-3">
          <SearchBar />
        </div>
      </div>
      <div className="shadow-2 rounded md:mb-0 xs:mb-4">
        <CarouselContainer>
          <Slider {...CarouselConfig}>
            {data.map((item: FeaturePost, index: number) => (
              <FeaturedPostItem key={index} {...item} />
            ))}
          </Slider>
        </CarouselContainer>
      </div>
    </div>
  );
};
export default FeaturedPosts;