import { FeaturePost } from "./Interface";
import CarouselImage from "./CarouselImage";
import CarouselTitle from "./CarouselTitle";
import CarouselDescription from "./CarouselDescription";
import CarouselBlogInfo from "./CarouselBlogInfo";

// Featured Blog Item
const FeaturedPostItem: React.FC<FeaturePost> = ({
  src,
  title,
  viewCount,
  author,
  date,
  updatedOn,
  readTime,
  description,
  blogLink,
  authorLink,
  defaultImageUrl,
  index
}) => {
  return (
    <div>
      <CarouselImage
        imageSrc={src}
        blogLink={blogLink}
        defaultImageUrl={defaultImageUrl}
        index={index}
      />
      <div className="mx-5 mb-5">
        <CarouselTitle
          title={title}
          viewCount={viewCount}
          blogLink={blogLink}
        />
        <CarouselBlogInfo
          author={author}
          authorLink={authorLink}
          date={date}
          readTime={readTime}
          updatedOn={updatedOn}
        />
        <CarouselDescription description={description} blogLink={blogLink} />
      </div>
    </div>
  );
};

export default FeaturedPostItem;
