import Link from "next/link";

type Props = {
  title: string;
  viewCount: number;
  blogLink: string;
};

// Blog Title & View Count
const CarouselTitle: React.FC<Props> = ({ title, viewCount, blogLink }) => {
  return (
    <div className="py-5 flex justify-between">
      <div className="xl:text-3xl md:h-75 xs:h-auto font-bold cursor-pointer sm:text-4xl xs:text-xl md:line-clamp-2 xl:line-clamp-2 pr-3">
        <Link href={blogLink}>{title}</Link>
      </div>
      <div className="text-gray-600 text-xs flex mt-2">
        <i className="blog-icons icon-eye pr-5"></i>
        {viewCount}
      </div>
    </div>
  );
};

export default CarouselTitle;
