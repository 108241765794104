import type { GetServerSideProps, NextPage } from "next";
import {
  blogCardArrangement,
  getBlogsForPaginationForHome,
} from "../../lib/apis/blog";
import MetaBody from "../../components/MetaBody";
import { IMeta } from "../../Interfaces/meta";
import { getMetaTagBySlug } from "../../lib/apis/meta";
import { blogCardList, IBlog, IBlogHome, Blog } from "../../Interfaces/blog";
import Button from "../../components/Button";
import Topics from "../../components/Topics";
import { useState } from "react";
import BlogListCard from "../../components/LatestPosts/BlogList";
import FormatDate from "../../components/FormatDate";
import FeaturedPosts from "../../components/FeaturedPosts";
import TrendingCarousel from "../../components/TrendingCarousel";
import ExitSuccessPopup from "../../components/ExitSuccessPopup";
import ExitCoursePopUp from "../../components/ExitCoursePopup";
import ExitPopUp from "../../components/ExitPopup";
import ExitMobileVerificationPopUp from "../../components/ExitMobileVerificationPopUp";
import { trackGa4Events } from "../../lib/events/ga4/tracker";
import { setLocation } from "../../lib/apis/location";
import { getPhoneConfigById } from "../../lib/apis/phone";
import { getRuleOutComes } from "../../lib/apis/ruleOutcome";

const BlogHomePage: NextPage = ({ ...data }: any) => {
  const {
    featuredBlogsList,
    popularBlogsList,
    popularTopics,
    blogsList,
    blogCount,
    meta,
    userAgent,
  }: IBlogHome = data;

  const [blogCardsList, setBlogCardsList] = useState<blogCardList[]>(blogsList);

  const [state, setState] = useState({ limit: 3, offset: 2 });

  const [customButton, setCustomButton] = useState({
    text: "Load More",
    varinat: "primary",
  });

  let shareImage = "blogs/share-image/blog-default-og.png";

  // getMoreBlogs
  const getMoreBlogs = async () => {
    // disable button
    trackGa4Events('click-latest-posts-load-more-latest-posts');
    setCustomButton({ text: "Loading....", varinat: "disabled" });

    const { result, totalCount } = await getBlogsForPaginationForHome(
      state.limit,
      (state.offset - 1) * state.limit,
      1,
      1
    );

    const { blogs } = result;

    // moreBlogCardsList
    const moreBlogCardsList = blogCardArrangement(blogs);

    setState({ ...state, offset: state.offset + 1 });

    setBlogCardsList([...blogCardsList, ...moreBlogCardsList]);

    //reset button
    setCustomButton({ text: "Load More", varinat: "primary" });
  };
  const [open, setOpen] = useState(true);

  return (
    <>
      <MetaBody props={{ meta: meta, shareImage: shareImage }} />
      <section className="py-7 bg-white xs:py-3">
        <div className="container mx-auto">
          <div className="grid grid-cols-12 xl:gap-7 xs:gap-0">
            <div className="xl:col-span-9 xs:col-span-12 sm:col-span-12">
              {featuredBlogsList && <FeaturedPosts data={featuredBlogsList} />}
            </div>
            <div className="xl:col-span-3 xs:col-span-12 sm:col-span-12">
              {popularBlogsList && <TrendingCarousel data={popularBlogsList} />}
            </div>
          </div>
        </div>
        {/* <ExitPopUp
          list={[
            { title: "Experience Outcome-Based Immersive Learning" },
            {
              title:
                "Globally Recognized Accreditations Case Studies and Real-World Simulations",
            },
            { title: "Training by Renowned Industry Experts" },
          ]}
          title={"Schedule 1:1 free counselling"}
          isOpen={open}
          setOpen={setOpen}
          badge="Talk to a Career Expert"
          description="Start your journey to become a Full-Stack Developer!"
          submitButton="Submit"
        /> */}
        {/* <ExitMobileVerificationPopUp
          list={[
            { title: "Experience Outcome-Based Immersive Learning" },
            {
              title:
                "Globally Recognized Accreditations Case Studies and Real-World Simulations",
            },
            { title: "Training by Renowned Industry Experts" },
          ]}
          title={"Schedule 1:1 free counselling"}
          isOpen={open}
          setOpen={setOpen}
          badge="Talk to a Career Expert"
          description="Start your journey to become a Full-Stack Developer!"
          submitButton="Submit"
        /> */}
        {/* <ExitCoursePopUp
          list={[
            { title: "Experience Outcome-Based Immersive Learning" },
            {
              title:
                "Globally Recognized Accreditations Case Studies and Real-World Simulations",
            },
            { title: "Training by Renowned Industry Experts" },
          ]}
          title={"Schedule 1:1 free counselling"}
          isOpen={open}
          setOpen={setOpen}
          badge="Talk to a Career Expert"
          description="Start your journey to become a Full-Stack Developer!"
          submitButton="Submit"
          courseInfoText="Help us tailor your experience by the finding the right course expert"
        /> */}
        {/* <ExitSuccessPopup
          isOpen={open}
          setOpen={setOpen}
          referAndEarnButton="Refer and Earn"
        /> */}
      </section>
      <section className="bg-white md:py-6 xs:pt-3">
        <div className="container mx-auto">
          <h2 className="mb-5 uppercase text-black font-bold text-sm relative before:absolute before:w-10 before:h-1 before:bg-black before:bottom-0 py-4 before:rounded-2xl">
            Topics
          </h2>
          <div className="grid grid-cols-1 xl:grid-cols-12 xl:gap-7 gap-0">
            {popularTopics &&
              popularTopics.map((topic) => {
                return (
                  <div
                    className="xl:col-span-3 xs:col-span-12 sm:col-span-12"
                    key={topic.id}
                  >
                    <Topics
                      data={{
                        icon: `icon-${topic.slug}-large`,
                        topicName: topic.name.toUpperCase(),
                        topicUrl: `/blog/type/${topic.slug}`,
                        ga4EventKey : `click-topics-${topic.slug}-topics`
                      }}
                    />
                  </div>
                );
              })}
          </div>
        </div>
      </section>
      <section className="bg-white md:pt-6 md:pb-12 xs:pt-3 xs:pb-8">
        <div className="container mx-auto">
          <h2 className="mb-5 uppercase text-black font-bold text-sm relative before:absolute before:w-10 before:h-1 before:bg-black before:bottom-0 py-4 before:rounded-2xl">
            Latest Posts
          </h2>
          <div className="grid grid-cols-1 xl:grid-cols-12 xl:gap-7 gap-0">
            {/* Blogs List */}
            {blogsList && (
              <BlogListCard
                blogs={blogCardsList}
                userAgent={userAgent}
              />
            )}
          </div>
          <div className="text-center">
            {blogsList.length < blogCount && (
              <div className="md:mt-6 xs:mt-2 text-center">
                <Button
                  onChildrenClick={getMoreBlogs}
                  text={customButton.text}
                  variant={customButton.varinat}
                />
              </div>
            )}
          </div>
        </div>
      </section>
    </>
  );
};

// getServerSideProps
export const getServerSideProps: GetServerSideProps = async (context) => {
  const { req } = context;

  const [limit, offset] = [3, 0];

  const { result, totalCount }: { result: IBlog; totalCount: number } =
    await getBlogsForPaginationForHome(limit, offset, 1);

  // blogMeta
  const blogMeta: IMeta = await getMetaTagBySlug("blog", 1);

  // result
  const { blogs, popularBlogs, popularTopics, featuredBlogs } = result;

  // featuredBlogsList
  const featuredBlogsList = featuredBlogs?.map((fBlog: Blog, key: number) => {
    const list = {
      author: fBlog.author?.first_name.concat(
        "  ",
        fBlog.author.last_name || ""
      ),
      authorLink: `/blog/author/${fBlog.author.code}`,
      blogLink: `/blog/${fBlog.blog_category.slug}/${fBlog.slug}`,
      date: FormatDate(fBlog.published_date, "DD MMM YYYY"),
      description: fBlog.content.replace(/(<([^>]+)>)/gi, "").substring(0, 150),
      readTime: `${fBlog.mins_of_read} mins`,
      src: `${process.env.NEXT_PUBLIC_AWS_URI}/${fBlog.banner_image}`,
      title: fBlog.title,
      updatedOn: FormatDate(fBlog.modified, "Do MMM, YYYY"),
      viewCount: fBlog.no_of_views,
      defaultImageUrl: `${process.env.NEXT_PUBLIC_AWS_URI}/assets/blog-default-img.png`,
      index: key + 1,
    };
    return list;
  });

  // popularBlogsList
  const popularBlogsList = popularBlogs?.map((pBlog: Blog, key: number) => {
    const list = {
      badge: [pBlog.blog_category.name],
      badgeLink: `/blog/category/${pBlog.blog_category.slug}`,
      link: `/blog/${pBlog.blog_category.slug}/${pBlog.slug}`,
      publishedOn: FormatDate(pBlog.modified, "DD MMM YYYY"),
      title: pBlog.title,
      index: key + 1,
    };
    return list;
  });

  // blogList
  const blogsList = blogs && blogCardArrangement(blogs);

    /* Start get ip address and set location and get course events */
  // setLocation

  const ip =
    (typeof req?.headers["x-forwarded-for"] == "string" &&
      req?.headers["x-forwarded-for"]?.split(",").shift()) ||
    req?.socket?.remoteAddress;

  // setLocation
  const responseData = await setLocation(
    "ip",
    typeof ip != "undefined" ? ip : "",
    typeof context.req.headers["user-agent"] != "undefined"
      ? context.req.headers["user-agent"]
      : ""
  );

  const phoneData = await getPhoneConfigById(responseData?.country.id);

   // topBannerData
 const topBannerData = await getRuleOutComes({
  ruleType: ["topBanner"],
  isShowAllRecords: false,
  facts: {
    country: `${responseData?.country?.id}`,
    city: `${responseData?.city?.id}`,
    region: `${responseData?.region?.id}`,
  },
});

  return {
    props: {
      featuredBlogsList,
      popularBlogsList,
      popularTopics,
      blogsList,
      blogCount: totalCount || 0,
      meta: blogMeta,
      userAgent: context.req.headers["user-agent"],
      phoneNumber: phoneData?.phone || "",
      topBannerData:topBannerData
    },
  };
};
export default BlogHomePage;
