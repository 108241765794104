import Link from "next/link";

type Props = {
  description: string;
  blogLink: string;
};

// Blog Description
const CarouselDescription: React.FC<Props> = ({ description, blogLink }) => {
  return (
    <div className="py-3 inline-block">
      <span
        className="xl:line-clamp-3 sm:line-clamp-4 xs:line-clamp-4 text-sm read-more-link"
        dangerouslySetInnerHTML={{ __html: description.trim() }}
      >
      </span>
      ... <span className="text-blue-600 underline text-sm">
        <Link href={blogLink}>Read more</Link>
      </span>
    </div>
  );
};

export default CarouselDescription;
