import { trackGa4Events } from "../../lib/events/ga4/tracker";
import { TrendingCarouselData } from "./Interface";
import Link from "next/link";

const TrendingItem: React.FC<TrendingCarouselData> = ({
  title,
  publishedOn,
  badge,
  link,
  badgeLink,
  index,
}) => {
  return (
    // Container
    <div className={`py-5 md:py-5 border-solid border-b border-gray-4 ${index % 3 === 0 && "border-b-0"}`} > 
      <Link href={link} passHref>
        <a target="_blank" rel="noopener noreferrer nofollow"
          onClick={() => {
            trackGa4Events(`click-trending-posts-blog-trending-posts{${title}}`);
          }}
        >
          {/* Title */}
          <div className="font-semibold pb-0.5 text-sm">{title}</div>
        </a>
      </Link>

      {/* Published on info */}
      <div className="py-1 text-xs pt-2 pb-3">Published on {publishedOn}</div>

      {/* Badge Container */}
      <Link href={badgeLink} passHref>
        <a target="_blank" rel="noopener noreferrer nofollow">
          <div className="flex flex-wrap">
            {/* Badge */}
            {badge.map((badgeItem: string, index: number) => (
              <span
                onClick={() => {
                  trackGa4Events(`click-trending-posts-category-trending-posts{${badgeItem}}`);
                }}
                key={index}
                className="px-3 mr-1 mb-1 text-10 font-bold leading-5 cursor-pointer rounded-full border border-black"
              >
                {badgeItem}
              </span>
            ))}
          </div>
        </a>
      </Link>
    </div>
  );
};

export default TrendingItem;
