import Link from "next/link";
import Image from "next/image";
import { useState } from "react";

type Props = {
  imageSrc: string;
  blogLink: string;
  defaultImageUrl:string;
  index: number;
};

// Blog Image
const CarouselImage: React.FC<Props> = ({ imageSrc, blogLink, defaultImageUrl, index }) => {

  const [src, setSrc] = useState(imageSrc);
  return (
    <div className="h-80 sm:h-auto xs:h-auto">
      <Link href={blogLink}>
        <a className="block">
          <Image
            src={src}
            alt="blog image"
            className="cursor-pointer h-80 xs:h-auto"
            height="320"
            width="848"
            onError={() => setSrc(defaultImageUrl)}
            priority={index === 1 ? true : false}
          />
        </a>
      </Link>
    </div>
  );
};

export default CarouselImage;
