import React, { useEffect, useState, Fragment, FC } from "react";
import Image from "next/image";
import Link from "next/link";
import { Blog, blogCardList, IBlog } from "../../Interfaces/blog";
import BlogCard from ".";
import { BlogList, Props } from "./interface";
import NewsArticleSnippet from "../NewsArticleSnippet";
import isBotUser from "../../lib/checkBot";
import {
  getBlogListNewsArticleSnippet,
  getNewsArticleSnippet,
} from "../../lib/apis/blog";

const BlogListCard: FC<BlogList & {addArticleSchema?: boolean}> = ({ blogs, userAgent, priorityState, isShowPublishedDate, addArticleSchema = true }) => {
  const [isBot, setIsBot] = useState(isBotUser(userAgent || ""));
  
  let newsArticleSnippetData: any;

  if (isBot && addArticleSchema) {
    newsArticleSnippetData = getBlogListNewsArticleSnippet(blogs);
  }
  return (
    <>
      {blogs &&
        blogs?.map((blog: blogCardList, index: number) => {
          return (
            <Fragment key={index}>
              <div
                className="xl:col-span-4 xs:col-span-12 sm:col-span-12 xs:mb-4 md:mb-0"
                key={blog.id}
              >
                <BlogCard data={blog} priorityState={priorityState} isShowPublishedDate={isShowPublishedDate} />
              </div>
            </Fragment>
          );
        })}
      {/* {News Article Snippet} */}
      {isBot && (
        <script
          type="application/ld+json"
          dangerouslySetInnerHTML={{
            __html: JSON.stringify(newsArticleSnippetData),
          }}
        />
      )}
    </>
  );
};
export default BlogListCard;
