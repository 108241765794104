import { useRouter } from "next/router";
import React from "react";
import { trackGa4Events } from "../../lib/events/ga4/tracker";

const SearchBar: React.FC = () => {
  const [query, setQuery] = React.useState("");
  const router = useRouter();

  // Input changes
  const handleChange = (value: string) => {
    setQuery(value);
  };

  // Checks pressed key
  const checkKey = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      handleSearch();
    }
  };

  // Search
  const handleSearch = () => {
    if (query.trim()) {
      router.push(`/blog/search?q=${query.trim()}&sort_by=title`);   
      trackGa4Events('click-search-featured-blog-posts');   
    }
  };

  return (
    <div className="md:w-250 flex items-center relative xs:w-full">
      <input
        className="md:w-250 xs:w-full h-9 text-base p-3 pr-10 border border-gray-8 rounded focus-visible:border-blue-4 focus-visible:outline-none placeholder-shown:text-sm placeholder-shown:text-black text-black placeholder:text-black"
        type="text"
        placeholder="Search..."
        value={query}
        onChange={(event) => handleChange(event.target.value)}
        onKeyPress={(event) => checkKey(event)}
      />
      <div className="absolute right-3 top-2" onClick={handleSearch}>
        {/* <SearchIcon className="w-6 cursor-pointer" onClick={handleSearch} /> */}
        <i className="blog-icons icon-search-black-large inline-block cursor-pointer"></i>
      </div>
    </div>
  );
};

export default SearchBar;
